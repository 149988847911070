import React, { FC } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import SearchableDropdown from '~/modules/onboarding-new/components/SearchableDropdown';
import { ONBOARDING_CARDS } from '~/modules/onboarding-new/constants';
import { TemplateVariantProps } from '~/modules/onboarding-new/types';

import styles from './styles.module.scss';

const TemplateVariant2: FC<
  TemplateVariantProps & {
    onSubmit: (value: string, nextKey?: ONBOARDING_CARDS) => void;
    onClick: () => void;
    value?: string;
  }
> = ({ value, onSubmit, onClick, variants, Text, isWithDot, ...props }) => {
  const { t } = useTranslation('onboarding');

  return (
    <div
      className={clsx(styles.text, {
        [styles['text--active']]: !value,
        [styles['text--pointer']]: Boolean(value),
      })}
      onClick={value ? onClick : undefined}
    >
      <Text>
        {value ? (
          <span className={styles.text__variable}>
            {variants.find((item) => item.value === value)?.getText(t) || value}
          </span>
        ) : (
          <span className={styles.text__variants}>
            <SearchableDropdown
              onSubmit={(value, nextCard): void =>
                onSubmit(value, nextCard || props.nextCard)
              }
              placeholder={t('form.input_placeholder', 'Type or choose')}
              containerClassName={styles.select_container}
              options={variants.map(({ getText, value, nextCard }) => ({
                text: getText(t),
                value,
                nextCard,
              }))}
            />
          </span>
        )}
      </Text>
      {value && isWithDot ? '.' : null}
    </div>
  );
};

export default TemplateVariant2;
