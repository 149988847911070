import React, { ReactNode } from 'react';

import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

export const getCountText = (
  query: string,
  minCountWarning?: number,
  maxCountIndicator?: number,
): { countText: string | null; minCountText: ReactNode | null } => {
  const { t } = useTranslation('error');
  const countText = maxCountIndicator
    ? query && typeof query === 'string'
      ? `${query.length}/${maxCountIndicator}`
      : `0 ${t('general:chars', 'chars')}`
    : null;

  const minCountText: ReactNode | null =
    minCountWarning &&
    query &&
    typeof query === 'string' &&
    query.length < minCountWarning ? (
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <Icon icon="ci:octagon-warning" width="15" />
        <span style={{ fontWeight: '600', lineHeight: 1 }}>
          {t(
            'input_more_details',
            'Please provide more details for better results',
          )}
          {countText ? <> {countText}</> : null}
        </span>
      </div>
    ) : null;

  return { countText, minCountText };
};
