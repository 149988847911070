import React, { FC, useState } from 'react';

import { Collapse, List, ListItem, styled } from '@mui/material';
import clsx from 'clsx';

import { ONBOARDING_CARDS } from '~/modules/onboarding-new/constants';
import { colors } from '~/theme/colors';

import styles from './styles.module.scss';

// TODO! Check the problem, why if I use the same components from  '~/components/atoms/StyledMenuItem' and '~/components/atoms/StyledMenuItem'; Menu get extra opacity
export const StyledList = styled(List)(() => ({
  padding: '8px 0',
  borderRadius: '4px',
  backgroundColor: colors.neutral_80,
}));

export const StyledListItem = styled(ListItem)(() => ({
  overflow: 'hidden',
  display: 'flex',
  padding: '14px 12px',
  gap: '8px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  cursor: 'pointer',

  '&:hover': {
    background: 'rgba(29, 27, 32, 0.08)',
  },
}));

type OptionType = {
  text: string;
  value: string;
};

type Props = {
  options: Array<
    OptionType & {
      nextCard?: ONBOARDING_CARDS;
    }
  >;
  onSubmit: (value: string, nextCard?: ONBOARDING_CARDS) => void;
  containerClassName?: string;
  placeholder?: string;
};

const Dropdown: FC<Props> = ({
  onSubmit,
  options,
  containerClassName,
  placeholder,
}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  return (
    <div className={clsx(styles.main, containerClassName)}>
      <input
        className={styles.main__input}
        type="text"
        placeholder={placeholder}
        readOnly
        onFocus={(): void => setIsMenuOpened(true)}
      />

      <div
        className={clsx(styles.main__menu, {
          [styles['main__menu--visible']]: isMenuOpened,
        })}
      >
        <Collapse in={isMenuOpened}>
          <StyledList>
            {options.map(({ value, text, nextCard }) => (
              <StyledListItem
                className={styles.main__menu__item}
                key={value}
                onClick={(): void => onSubmit(value, nextCard)}
              >
                {text}
              </StyledListItem>
            ))}
          </StyledList>
        </Collapse>
      </div>
    </div>
  );
};

export default Dropdown;
