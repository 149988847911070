import router, { RouteItemType } from '~/core/router';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';
import { getPromoTitle, PromoPath } from '~/modules/promotion/constants';

const PromoCheckoutComponent = lazyComponentLoader(
  () => import('./PromoCheckout'),
);

const Promo: RouteItemType = {
  Component: PromoCheckoutComponent,
  path: PromoPath,
  getTitle: getPromoTitle,
};

router.addPrivateRoutes([Promo]);
