import React from 'react';

import { TFunction } from 'i18next';

import styles from './styles.module.scss';

export enum Errors {
  INSUFFICIENT_FUNDS = 'Insufficient funds',
  INVALID_INPUT = 'Invalid input',
  DECLINED = 'declined',
  UNSPECIFIED = 'unspecified',
}

export const invalidInputErrors = [
  'Invalid card number',
  'Invalid expiration date',
  'Invalid CVV2 code',
  'Restricted card',
];

export const getPaymentErrors = (
  t: TFunction,
): Record<Errors, JSX.Element> => ({
  [Errors.INVALID_INPUT]: (
    <>
      <p>
        <b className={styles.bold}>
          {t('error:incorrect_card_details.', 'Incorrect card details.')}
        </b>
      </p>
      <p>
        {t(
          'error:enter_the_correct_card',
          'Please try again and enter the correct card details.',
        )}
      </p>
    </>
  ),
  [Errors.INSUFFICIENT_FUNDS]: (
    <>
      <p>
        <b className={styles.bold}>
          {t('error:payment_failed', 'Your payment has failed')}
        </b>
      </p>
      <p>
        {t(
          'error:check_payment_balance',
          'Please check the balance or try another payment method.',
        )}
      </p>
    </>
  ),
  [Errors.DECLINED]: (
    <>
      <p>
        <b className={styles.bold}>
          {t('error:payment_failed', 'Your payment has failed')}
        </b>
      </p>
    </>
  ),
  [Errors.UNSPECIFIED]: (
    <>
      <p>
        <b className={styles.bold}>
          {t('error:payment_failed', 'Your payment has failed')}
        </b>
      </p>
      <p>
        {t(
          'error:contact_card_issuer',
          'Please try again or contact your card issuer if the problem persists.',
        )}
      </p>
    </>
  ),
});

export const getPaymentErrorText = (
  paymentError: any,
  t: TFunction,
): JSX.Element => {
  const paymentErrors = getPaymentErrors(t);
  const parsedPaymentError =
    typeof paymentError === 'string' ? paymentError.replace(/\./g, '') : '';

  if (invalidInputErrors.includes(parsedPaymentError)) {
    return paymentErrors[Errors.INVALID_INPUT];
  }
  if (parsedPaymentError === Errors.INSUFFICIENT_FUNDS) {
    return paymentErrors[Errors.INSUFFICIENT_FUNDS];
  }
  if (parsedPaymentError === Errors.DECLINED) {
    return paymentErrors[Errors.DECLINED];
  }

  return paymentErrors[Errors.UNSPECIFIED];
};
