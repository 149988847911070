import React, { FC } from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import { ReactComponent as UploadAndroidIcon } from '~/features/pwa/assets/android_nav.svg';
import { ReactComponent as UploadDesktopChromeIcon } from '~/features/pwa/assets/desktop_chrome_nav.svg';
import { ReactComponent as UploadSafariIcon } from '~/features/pwa/assets/ios_nav.svg';
import { ReactComponent as UploadSafariIOSIcon } from '~/features/pwa/assets/ios_safari_nav.svg';
import { ReactComponent as UploadEdgeIcon } from '~/features/pwa/assets/windows_nav.svg';
import { PWADevice } from '~/features/pwa/constants';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  device: PWADevice;
  onSubmit: () => void;
};

type PwaScreenConfigType = Record<PWADevice, JSX.Element>;

const getConfig = (t: TFunction): PwaScreenConfigType => ({
  [PWADevice.Ios_Safari]: (
    <>
      <Typography
        typography={Typographies.HEADLINE_SMALL}
        component="h4"
        className={clsx(styles.headline, styles.mb_16)}
      >
        {t('modal:pwa.titles[0]', 'Add Justdone to Your Home Screen')}
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={styles.list_item}
      >
        1.
        <Trans i18nKey="modal:pwa.steps[0]" t={t}>
          Tap “ <UploadSafariIcon style={{ marginBottom: '-3px' }} /> ” at the
          bottom
        </Trans>
        .
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.mb_16)}
      >
        2.
        <Trans i18nKey="modal:pwa.steps[1]" t={t}>
          Tap “ Add to Home Screen{' '}
          <UploadSafariIOSIcon style={{ marginBottom: '-3px' }} /> ”
        </Trans>
        .
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.extra_info_text)}
      >
        {t(
          'modal:pwa.steps[2]',
          'Or add the page to your browser favorites for quick access',
        )}
        .
      </Typography>
    </>
  ),
  [PWADevice.Ios_Chrome]: (
    <>
      <Typography
        typography={Typographies.HEADLINE_SMALL}
        component="h4"
        className={clsx(styles.headline, styles.mb_16)}
      >
        Add Justdone to Your Home Screen
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={styles.list_item}
      >
        1. Tap “ <UploadSafariIcon style={{ marginBottom: '-3px' }} /> ” in
        address bar.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.mb_16)}
      >
        2. Tap “Add to Home Screen”.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.extra_info_text)}
      >
        Or add the page to your browser favorites for quick access.
      </Typography>
    </>
  ),
  [PWADevice.Android_Chrome]: (
    <>
      <Typography
        typography={Typographies.HEADLINE_SMALL}
        component="h4"
        className={clsx(styles.headline, styles.mb_16)}
      >
        {t('modal:pwa.titles[0]', 'Add Justdone to Your Home Screen')}
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={styles.list_item}
      >
        1.
        <Trans i18nKey="modal:pwa.steps[3]" t={t}>
          Tap “ <UploadAndroidIcon style={{ marginBottom: '-3px' }} /> ” in
          upper right-hand corner
        </Trans>
        .
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.mb_16)}
      >
        2. {t('modal:pwa.steps[4]', 'Tap “Install app”')}.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.extra_info_text)}
      >
        {t(
          'modal:pwa.steps[2]',
          'Or add the page to your browser favorites for quick access',
        )}
        .
      </Typography>
    </>
  ),
  [PWADevice.Mac_Chrome]: (
    <>
      <div className={styles.image}>
        <Image
          src="/icons/chrome-address-bar-small.png"
          preview="/icons/chrome-address-bar-small-preview.png"
        />
      </div>
      <Typography
        typography={Typographies.TITLE_LARGE}
        component="h4"
        className={clsx(styles.headline, styles.mb_16)}
      >
        {t('modal:pwa.titles[1]', 'Install Justdone application')}
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={styles.list_item}
      >
        1.
        <Trans i18nKey="modal:pwa.steps[5]" t={t}>
          Click “{' '}
          <UploadDesktopChromeIcon
            style={{
              height: '22px',
              marginTop: '-3px',
              marginBottom: '-3px',
            }}
          />{' '}
          ” at the top
        </Trans>
        .
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.mb_16)}
      >
        2. {t('modal:pwa.steps[6]', 'Click “Install”')}.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.extra_info_text)}
      >
        Or add the page to your browser <br /> favorites for quick access.
      </Typography>
    </>
  ),
  [PWADevice.Mac_Edge]: (
    <>
      <div className={styles.image} style={{ marginBottom: '16px' }}>
        <Image
          src="/icons/edge-address-bar.png"
          preview="/icons/edge-address-bar-preview.png"
        />
      </div>
      <Typography
        typography={Typographies.TITLE_LARGE}
        component="h4"
        className={clsx(styles.headline, styles.mb_16)}
      >
        Install Justdone application
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={styles.list_item}
      >
        1. Click “{' '}
        <UploadEdgeIcon
          style={{
            marginTop: '-3px',
            marginBottom: '-3px',
          }}
        />{' '}
        ” at the top.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.mb_16)}
      >
        2. Click “Install”.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.extra_info_text)}
      >
        Or add the page to your browser <br /> favorites for quick access.
      </Typography>
    </>
  ),
  [PWADevice.Mac_Safari]: (
    <>
      <div
        className={styles.image}
        style={{ width: '100%', maxWidth: '176px', marginBottom: '24px' }}
      >
        <Image
          src="/icons/safari-address-bar.png"
          preview="/icons/safari-address-bar-preview.png"
        />
      </div>
      <Typography
        typography={Typographies.TITLE_LARGE}
        component="h4"
        className={clsx(styles.headline, styles.mb_16)}
      >
        Install Justdone application
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={styles.list_item}
      >
        1. Click “{' '}
        <UploadSafariIcon
          style={{
            marginTop: '-3px',
            marginBottom: '-3px',
          }}
        />{' '}
        ” at the top.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.mb_16)}
      >
        2. Click “Add to Dock”.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.extra_info_text)}
      >
        {t(
          'modal:pwa.steps[2]',
          'Or add the page to your browser favorites for quick access',
        )}
        .
      </Typography>
    </>
  ),
  [PWADevice.Windows_Chrome]: (
    <>
      <div className={styles.image}>
        <Image
          src="/icons/chrome-address-bar-small.png"
          preview="/icons/chrome-address-bar-small-preview.png"
        />
      </div>
      <Typography
        typography={Typographies.TITLE_LARGE}
        component="h4"
        className={clsx(styles.headline, styles.mb_16)}
      >
        {t('modal:pwa.titles[1]', 'Install Justdone application')}
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={styles.list_item}
      >
        1.
        <Trans i18nKey="modal:pwa.steps[5]" t={t}>
          Click “{' '}
          <UploadDesktopChromeIcon
            style={{
              height: '22px',
              marginTop: '-3px',
              marginBottom: '-3px',
            }}
          />{' '}
          ” at the top
        </Trans>
        .
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.mb_16)}
      >
        2. {t('modal:pwa.steps[6]', 'Click “Install”')}.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.extra_info_text)}
      >
        <Trans t={t} i18nKey={'modal:pwa.steps[2]'}>
          Or add the page to your browser <br /> favorites for quick access.
        </Trans>
      </Typography>
    </>
  ),
  [PWADevice.Windows_Edge]: (
    <>
      <div className={styles.image} style={{ marginBottom: '16px' }}>
        <Image
          src="/icons/edge-address-bar.png"
          preview="/icons/edge-address-bar-preview.png"
        />
      </div>
      <Typography
        typography={Typographies.TITLE_LARGE}
        component="h4"
        className={clsx(styles.headline, styles.mb_16)}
      >
        Install Justdone application
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={styles.list_item}
      >
        1. Click “{' '}
        <UploadEdgeIcon
          style={{
            marginTop: '-3px',
            marginBottom: '-3px',
          }}
        />{' '}
        ” at the top.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.mb_16)}
      >
        2. {t('modal:pwa.steps[6]', 'Click “Install”')}.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.extra_info_text)}
      >
        {t(
          'modal:pwa.steps[2]',
          'Or add the page to your browser favorites for quick access',
        )}
        .
      </Typography>
    </>
  ),
});

const AddToHomeModalV1: FC<Props> = ({ device, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.container}>{getConfig(t)[device]}</div>
      <Button
        variant="contained"
        onClick={(): void => {
          onSubmit();
        }}
      >
        {t('general:got_it', 'Got it')}
      </Button>
    </>
  );
};

export default AddToHomeModalV1;
