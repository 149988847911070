import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { Trans, useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import ModalContentText from '~/components/atoms/ModalContentText';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import { colors } from '~/theme/colors';

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
};
const DeleteAllDataModal: FC<Props> = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation('modal');

  return (
    <div>
      <ModalContentTitle text={t('delete_all_data.title', 'Delete all data')} />
      <div
        style={{
          display: 'flex',
          marginBottom: '32px',
          gap: '15px',
        }}
      >
        <Icon
          style={{ fontSize: 53, color: colors.error_40, flexShrink: 0 }}
          icon="material-symbols:warning-rounded"
        />
        <ModalContentText>
          <Trans i18nKey="delete_all_data.text" t={t}>
            Data removal of your account will result in complete losing of
            access to account and history of your work.
            <b>This action cannot be reverted.</b>
          </Trans>
        </ModalContentText>
      </div>

      <div>
        <Button onClick={onCancel} variant="contained">
          {t('cancel', { ns: 'general', defaultValue: 'Cancel' })}
        </Button>
        <Button onClick={onSubmit} variant="text">
          {t('delete_all_data.submit_button', 'I wish to proceed')}
        </Button>
      </div>
    </div>
  );
};

export default DeleteAllDataModal;
