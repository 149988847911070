import React, { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import CardImg from '~/assets/images/credit_card.webp';
import CardPreviewImg from '~/assets/images/credit_card_preview.png';
import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import ModalContentText from '~/components/atoms/ModalContentText';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import analytics from '~/services/analytics';

import styles from './styles.module.scss';

type Props = {
  onSubmit: () => Promise<void>;
  onClose: () => void;
};

const RedemptFailedSub: FC<Props> = ({ onSubmit, onClose }) => {
  const { t } = useTranslation('modal');

  const handleSubmit = async (): Promise<void> => {
    analytics.trackEvent('subscription - failed redemption fix click');
    await onSubmit();
  };

  const handleRefuse = (): void => {
    analytics.trackEvent('subscription - close failed redemption screen');
    onClose();
  };

  useEffect(() => {
    analytics.trackEvent('subscription - failed redemption screen');
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Image src={CardImg} preview={CardPreviewImg} />
      </div>

      <ModalContentTitle
        className={styles.title}
        text={t(
          'redempt_failed.title',
          "We couldn't fix the payment problem automatically",
        )}
      />

      <ModalContentText sx={{ mb: '32px', maxWidth: 'unset' }}>
        {t(
          'redempt_failed.text',
          'To process your payment, please update your payment method.',
        )}
      </ModalContentText>

      <div className={styles.buttons_container}>
        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          {t('general:update_payment_method', 'Update Payment Method')}
        </Button>

        <Button
          className={styles.button}
          variant="text"
          color="primary"
          onClick={handleRefuse}
        >
          {t('general:exit', 'Exit')}
        </Button>
      </div>
    </div>
  );
};

export default RedemptFailedSub;
