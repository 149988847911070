import { TFunction } from 'i18next';

export const ToolsSidebarKey = 'tools';

export const ToolsGalleryPath = '/tools';

export const getToolsGalleryTitle = (t: TFunction): string =>
  t('general:tools', 'Tools');

export const AdvancedArticleGeneratorPath = '/tools/advanced_article_generator';

export const getAdvancedArticleGeneratorTitle = (t: TFunction): string =>
  t('tools:title.long_article_generator', 'Long Article (1000+ Words)');

export const PaperWriterPath = '/tools/paper_writer';

export const getPaperWriterTitle = (t: TFunction): string =>
  t('tools:title.paper_writer', 'Paper Writer');

export const PlagiarismCheckerPath = '/tools/plagiarism_checker';

export const getPlagiarismCheckerTitle = (t: TFunction): string =>
  t('tools:title.plagiarism_checker', 'Plagiarism Checker');

export const AiDetectorPath = '/tools/ai_content_detector';

export const getAiDetectorTitle = (t: TFunction): string =>
  t('tools:title.ai_content_detector', 'AI Content Detector');

export const AiHumanizerPath = '/tools/ai_text_humanizer';

export const getAiHumanizerTitle = (t: TFunction): string =>
  t('tools:title.ai_humanizer', 'AI Text Humanizer');

export const ParaphraseTextPath = '/tools/paraphrase_text';

export const getParaphraseTextTitle = (t: TFunction): string =>
  t('tools:title.paraphrase_text', 'Paraphrase Text');

export const SummariseTextPath = '/tools/summarize_text';

export const getSummariseTextTitle = (t: TFunction): string =>
  t('tools:title.summarise_text', 'Summarise Text');

export const RewriteArticlePath = '/tools/rewrite_article';

export const getRewriteArticleTitle = (t: TFunction): string =>
  t('tools:title.rewrite_article', 'Rewrite Article');

export const ToolPath = '/tools/:slug';

export const getToolTitle = (t: TFunction): string =>
  t('tools:tools_item', 'Tools item');

export const validExtensions = ['docx', 'txt', 'pdf'];
export const MAX_FILE_SIZE = 10000000; //10MB

export const GRAMMAR_CHECK_TOOL_ID = '3a72d3d8-77e0-423e-9cf6-de3b346e0235';
export const SUMMARIZE_TEXT_TOOL_ID = '8928337a-ca77-4fd2-a161-b5572b508fa6';

export const CITATION_MACHINE_TOOL_ID = '289c2a3c-2fd8-4714-bc81-635215ec6613';

export const SUMMARIZE_TOOL_ID = '8928337a-ca77-4fd2-a161-b5572b508fa6';

export const getFormSubmitButtonTextConfig = (
  t: TFunction,
): Record<string, string> => ({
  [GRAMMAR_CHECK_TOOL_ID]: t('general:check', 'Check'),
});

export const WIDE_FORM_REQUEST_TOOLS = [GRAMMAR_CHECK_TOOL_ID];

export const AVAILABLE_IN_EXTENSION_TOOL_ID = [
  GRAMMAR_CHECK_TOOL_ID,
  SUMMARIZE_TOOL_ID,
];
