import { TFunction } from 'i18next';
import * as yup from 'yup';

export const getFormSchema = (t: TFunction): any =>
  yup
    .object()
    .shape({
      phrase: yup
        .string()
        .trim()
        .required(
          t('phrase_required', {
            ns: 'error',
            defaultValue: 'Phrase is required',
          }),
        )
        .test(
          'test-phrase',
          t('confirmation_phrase_incorrect', {
            ns: 'error',
            defaultValue: 'Confirmation phrase is incorrect',
          }),
          (value) => {
            if (value) return value === 'Delete My Account';
            return true;
          },
        ),

      password: yup
        .string()
        .trim()
        .required(
          t('password_required', {
            ns: 'error',
            defaultValue: 'Password is required',
          }),
        ),
    })
    .required();
