export enum AB_TESTS_KEYS {
  PWA_COMMUNICATION_V4_1 = 'pwa-v4.1-re-test',
  CHAT_ONBOARDING = 'chat_onboarding',
  TRUSTPILOT_FEEDBACK = 'trustpilot_feedback',
  RATEBAR_COLLECTION_FEEDBACK = 'ratebar-collection-type',
}

export const AB_TEST_FEATURES: Record<
  AB_TESTS_KEYS,
  {
    key: AB_TESTS_KEYS;
    variants: {
      [key: string]: string;
    };
    defaultVariant: string;
  }
> = {
  [AB_TESTS_KEYS.PWA_COMMUNICATION_V4_1]: {
    key: AB_TESTS_KEYS.PWA_COMMUNICATION_V4_1,
    variants: {
      control: '4.1',
      variant_1: '4.0',
    },
    defaultVariant: '4.1',
  },
  [AB_TESTS_KEYS.CHAT_ONBOARDING]: {
    key: AB_TESTS_KEYS.CHAT_ONBOARDING,
    variants: {
      control: 'control',
      variant_1: 'tested',
    },
    defaultVariant: 'control',
  },
  [AB_TESTS_KEYS.TRUSTPILOT_FEEDBACK]: {
    key: AB_TESTS_KEYS.TRUSTPILOT_FEEDBACK,
    variants: {
      variant_1: 'variant_1', // with modal
      variant_2: 'variant_2', // without modal
    },
    defaultVariant: 'control',
  },
  [AB_TESTS_KEYS.RATEBAR_COLLECTION_FEEDBACK]: {
    key: AB_TESTS_KEYS.RATEBAR_COLLECTION_FEEDBACK,
    variants: {
      control: 'control', // with emoji
      variant_1: 'test', // with stars
    },
    defaultVariant: 'control',
  },
};
