import { TFunction } from 'i18next';

export const PersonalInformationPath = '/settings/personal-information';

export const getPersonalInformationTitle = (t: TFunction): string =>
  t('general:personal_information', 'Personal Information');

export const SecurityPath = '/settings/security';

export const getSecurityTitle = (t: TFunction): string =>
  t('general:password', 'Password');

export const SubscriptionPath = '/settings/subscription';
export const getSubscriptionTitle = (t: TFunction): string =>
  t('general:subscription', 'Subscription');
