import { useSnackBarContext } from '~/features/snackbar/SnackbarContext';
import i18n from '~/i18n/config';
import analytics from '~/services/analytics';

export type UserErrorData = {
  error: any;
  method: string;
  userMessage: string;
  isReportButton?: boolean;
  isFullWidth?: boolean;
};

const { t } = i18n;

const useErrors = (): {
  reportUserErrors: (data: UserErrorData) => void;
  reportProfileError: (e) => void;
} => {
  const { showSnackbar } = useSnackBarContext();

  const reportUserErrors = (data: UserErrorData): void => {
    const {
      error,
      method,
      userMessage,
      isReportButton = true,
      isFullWidth = false,
    } = data;

    const errorCode = error.status || 'Code :(';
    const errorMessage = error.error || error.message || 'Error :(';
    const eventProperties = {
      error_method: method,
      error_text: errorMessage,
      error_code: errorCode,
    };

    showSnackbar(
      errorMessage === 'Server Error'
        ? t('server_error', {
            ns: 'error',
            defaultValue:
              'Server error encountered. Please try again later or reach out to support or',
          })
        : userMessage,
      'report',
      !isReportButton ? 3000 : 10000,
      '',
      () => {
        analytics.trackEvent('error - error bar close', eventProperties);
      },
      () => {
        analytics.trackEvent('error - error bar click', eventProperties);
      },
      () => {
        analytics.trackEvent('error - error bar view', eventProperties);
      },
      isFullWidth,
      isReportButton,
    );
  };

  const reportProfileError = (e: any): void => {
    reportUserErrors({
      error: e,
      method: 'getProfile',
      userMessage: t('get_profile', {
        ns: 'error',
        defaultValue:
          'Error retrieving profile data. Please refresh the page or',
      }),
    });
  };

  return { reportUserErrors, reportProfileError };
};

export default useErrors;
