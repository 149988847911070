import React, { FC, ReactElement } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getExtraProductDescriptions } from '~/components/organisms/PaymentForm/components/OrderDescription/constants';
import { Typographies } from '~/theme/typography';
import { LimitsRenewPeriod } from '~/types/common';
import { REGULARITY } from '~/types/product';

import styles from './styles.module.scss';

export type OrderDescriptionType = {
  details_list_ui: Array<string | ReactElement>;
  image_src_ui: string;
};

export type Props = {
  productId: string;
  productName: string;
  productRegularity: REGULARITY;
  isPopular: boolean;
};

const OrderDescription: FC<Props> = ({
  productId,
  productName,
  productRegularity,
  isPopular,
}) => {
  const { t } = useTranslation('subscription');

  const extraProductDescription = getExtraProductDescriptions(
    productId,
    productRegularity,
    LimitsRenewPeriod.MONTH,
    t,
  );

  return (
    <div className={styles.wrapper}>
      {isPopular && (
        <Typography
          className={styles.popular}
          variant={Typographies.BODY_SMALL}
          component="p"
        >
          {t('general:most_popular', 'Most Popular')}
        </Typography>
      )}

      {extraProductDescription.image_src_ui && (
        <img
          src={extraProductDescription.image_src_ui}
          alt=""
          className={styles.image}
        />
      )}

      <Typography
        className={styles.title}
        variant={Typographies.TITLE_MEDIUM}
        component="h3"
      >
        {productName}
      </Typography>

      <Typography
        className={styles.details_title}
        variant={Typographies.LABEL_LARGE}
        component="h3"
      >
        {t('payment_form.order_details_title', 'Your order details:')}
      </Typography>

      <div>
        {extraProductDescription.details_list_ui.map((detail, idx) => (
          <Typography
            key={idx}
            className={styles.detail}
            variant={Typographies.BODY_MEDIUM}
            component="h3"
          >
            {detail}
          </Typography>
        ))}
      </div>
    </div>
  );
};

export default OrderDescription;
