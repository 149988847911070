import React, { FC, ReactElement, ReactNode } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LogoSystemComponent } from '~/assets/icons/jd_logo_v3.svg';
import { ReactComponent as LogoPromoComponent } from '~/assets/icons/jd_logo_v4.svg';
import { ReactComponent as StarsIcon } from '~/assets/icons/text-ai-edit/stars.svg';
import FallbackLetterAvatar from '~/components/atoms/FallbackLetterAvatar';
import { getPersonalityConf } from '~/modules/chat/components/Chat/elements/Personality';
import { PERSONALITY } from '~/modules/chat/types';
import { useAppSelector } from '~/store/hooks/useAppSelector';

import styles from './styles.module.scss';

export enum AvatarType {
  SYSTEM = 'system',
  PROMO = 'promo',
  PERSONALITY = 'personality',
  USER = 'user',
  AI_EDIT = 'ai_edit',
}

type Props =
  | {
      children: ReactNode;
      avatarType: Exclude<AvatarType, AvatarType.PERSONALITY>;
      personality?: PERSONALITY;
      className?: string;
    }
  | {
      children: ReactNode;
      avatarType: AvatarType.PERSONALITY;
      personality: PERSONALITY;
      className?: string;
    };

const LogoMessageWrapper: FC<Props> = ({
  children,
  avatarType,
  personality,
  className,
}) => {
  const user = useAppSelector((state) => state.profile);
  const { t } = useTranslation();

  const personalityConf = getPersonalityConf(t);

  const renderLogo = (): ReactElement => {
    switch (avatarType) {
      case AvatarType.SYSTEM:
        return (
          <FallbackLetterAvatar variant={avatarType}>
            <LogoSystemComponent />
          </FallbackLetterAvatar>
        );
      case AvatarType.PROMO:
        return (
          <FallbackLetterAvatar variant={avatarType}>
            <LogoPromoComponent />
          </FallbackLetterAvatar>
        );
      case AvatarType.PERSONALITY:
        return (
          <FallbackLetterAvatar variant={avatarType}>
            {personalityConf[personality]?.logo}
          </FallbackLetterAvatar>
        );
      case AvatarType.USER:
        return (
          <FallbackLetterAvatar logo_src={user.avatar} variant={avatarType}>
            {user.name.charAt(0)}
          </FallbackLetterAvatar>
        );
      case AvatarType.AI_EDIT:
        return (
          <FallbackLetterAvatar variant={avatarType}>
            <StarsIcon />
          </FallbackLetterAvatar>
        );
    }
  };

  return (
    <div className={clsx(styles.message, className, styles[avatarType])}>
      <div className={styles.message__inner_flex}>
        {renderLogo()}
        <div className={styles.message__content}>{children}</div>
      </div>
    </div>
  );
};

export default LogoMessageWrapper;
