import React, {
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { styled } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

import Api from '~/Api';
import { getToolTitle } from '~/helpers/translation';
import useErrors from '~/hooks/useErrors';

import { getFormOptions } from './constants';
import FeedbackStep from './FeedbackStep';
import ReasonStep from './ReasonStep';
import styles from './styles.module.scss';
import { CancellationFormDataType, FormFields } from '../../types';

const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
}));

export enum FORM_STEP {
  REASON = 'reason',
  FEEDBACK = 'feedback',
}

const initialFormState = {
  [FormFields.REASON]: '',
  [FormFields.SUB_REASON]: '',
  [FormFields.RELATED_TO]: '',
  [FormFields.FEEDBACK]: '',
};

type Props = {
  title: string;
  onFormSubmit: (data: CancellationFormDataType) => Promise<void>;
  onFormRefuse: () => void;
};

const FormMultiple: FC<Props> = ({ title, onFormSubmit, onFormRefuse }) => {
  const [form, setForm] = useState<CancellationFormDataType>(initialFormState);
  const [relations, setRelations] = useState<{ text: string; value: string }[]>(
    [],
  );
  const [currentStep, setCurrentStep] = useState(FORM_STEP.REASON);

  const { t } = useTranslation('cancellation');
  const scrollElemRef = useRef<HTMLDivElement | null>(null);
  const { reportUserErrors } = useErrors();

  const formOptions = getFormOptions(t);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const { user_last_tool, user_popular_tool, is_last_chat_used } =
          await Api.getUsageStatistics();

        const relations: { text: string; value: string }[] = [];

        if (is_last_chat_used) {
          relations.push({ text: t('general:chat', 'Chat'), value: 'Chat' });
        }

        if (user_last_tool) {
          relations.push({
            text: getToolTitle(user_last_tool.id, user_last_tool.name, t),
            value: user_last_tool.name,
          });
        }

        if (user_popular_tool && user_popular_tool.id !== user_last_tool?.id) {
          relations.push({
            text: getToolTitle(user_popular_tool.id, user_popular_tool.name, t),
            value: user_popular_tool.name,
          });
        }

        if (relations.length > 0) {
          setRelations([
            ...relations,
            { text: t('general:other'), value: 'Other' },
          ]);
        }
      } catch (e: any) {
        reportUserErrors({
          error: e,
          method: 'getUsageStatistics',
          userMessage: t('get_usage_statistics', {
            ns: 'error',
            defaultValue:
              'Usage statistics are currently unavailable. Please try later or',
          }),
        });
      }
    })();
  }, []);

  const handleGoToNextStep = (
    data: Partial<CancellationFormDataType>,
    step: FORM_STEP,
  ): void => {
    setForm((prev) => ({ ...prev, ...data }));
    setCurrentStep(step);

    scrollElemRef.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  const STEPS: Record<FORM_STEP, ReactElement<any, any>> = useMemo(() => {
    return {
      [FORM_STEP.REASON]: (
        <ReasonStep
          title={title}
          options={formOptions}
          relations={relations}
          onRefuse={onFormRefuse}
          onSubmit={(data): void =>
            handleGoToNextStep(data, FORM_STEP.FEEDBACK)
          }
        />
      ),
      [FORM_STEP.FEEDBACK]: (
        <FeedbackStep
          title={
            formOptions[form.reason]?.feedbackText ||
            t('v3.default.feedback_title', {
              ns: 'cancellation',
              defaultValue: 'Tell us more about your experience.',
            })
          }
          onRefuse={onFormRefuse}
          onSubmit={(data): Promise<void> =>
            onFormSubmit({
              ...form,
              [FormFields.FEEDBACK]: data.feedback,
            })
          }
        />
      ),
    };
  }, [form, relations]);

  return (
    <div className={styles.container} ref={scrollElemRef}>
      <StyledFormControl error={!form.reason}>
        {STEPS[currentStep]}
      </StyledFormControl>
    </div>
  );
};

export default FormMultiple;
