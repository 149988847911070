import { FC } from 'react';

import { TFunction } from 'i18next';

import { SignInPath } from '~/modules/auth/constants';
import { HomePagePath } from '~/modules/home/constants';
import { ProfileType } from '~/types/profile';

export type RouteItemType = {
  path: string;
  Component: FC;
  getTitle: (t: TFunction) => string;
  permissionKey?: string;
  checkAvailability?: (profile: ProfileType) => boolean;
};

class Router {
  private authRoutes: RouteItemType[] = [];
  private privateRoutes: RouteItemType[] = [];
  private unAuthorizedRoutes: RouteItemType[] = [];
  private signUpRedirectPath: string | null = null;

  constructor() {
    this.authRoutes = [];
    this.privateRoutes = [];
    this.unAuthorizedRoutes = [];
  }

  addUnAuthorizedRoutes(route: RouteItemType[]): void {
    this.unAuthorizedRoutes = [...this.unAuthorizedRoutes, ...route];
  }

  addAuthRoutes(route: RouteItemType[]): void {
    this.authRoutes = [...this.authRoutes, ...route];
  }

  getAuthRoutes(): RouteItemType[] {
    return this.authRoutes;
  }

  getSignInRoutePath(): string {
    return SignInPath;
  }

  addPrivateRoutes(route: RouteItemType[]): void {
    this.privateRoutes = [...this.privateRoutes, ...route];
  }

  getPrivateRoutes(): RouteItemType[] {
    return this.privateRoutes;
  }

  getUnAuthorizedRoutes(): RouteItemType[] {
    return this.unAuthorizedRoutes;
  }

  getAvailablePrivateRoutes(profile: ProfileType): RouteItemType[] {
    return this.privateRoutes.filter(({ checkAvailability }) =>
      checkAvailability ? checkAvailability(profile) : true,
    );
  }

  getDefaultPrivateRoutePath(): string {
    return HomePagePath;
  }

  setRedirectSignUpPath(path: string | null): void {
    this.signUpRedirectPath = path;
  }
  getRedirectSignUpPath(): string | null {
    return this.signUpRedirectPath;
  }
}

export default new Router();
