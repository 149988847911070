import React, { ReactElement } from 'react';

import { Icon as SidebarIcon } from '@iconify/react';
import { flowRight } from 'lodash';

import router, { RouteItemType } from '~/core/router';
import { sidebar } from '~/core/sidebar';
import {
  withCheckoutOpeningLayout,
  withHomeScreenRetentionLayout,
  withMainLayout,
  withOnboardingLayout,
  withPWAModalLayout,
  withSubModalsLayout,
} from '~/decorators/withLayout';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';

import {
  getHomePageTitle,
  HomePagePath,
  HomePageSidebarKey,
} from './constants';

const HomePage = lazyComponentLoader(() => import('./views/HomePage'));

const HomePageRoute: RouteItemType = {
  Component: flowRight(
    withCheckoutOpeningLayout,
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(HomePage),
  path: HomePagePath,
  getTitle: getHomePageTitle,
};

router.addPrivateRoutes([HomePageRoute]);

sidebar.addItems({
  a: [
    {
      order: 1,
      key: HomePageSidebarKey,
      path: HomePageRoute.path,
      getTitle: HomePageRoute.getTitle,
      Icon: ({ isActive }): ReactElement => {
        return (
          <SidebarIcon
            icon={
              isActive
                ? `material-symbols:home`
                : `material-symbols:home-outline`
            }
          />
        );
      },
    },
  ],
});
