import { TFunction } from 'i18next';

enum REASON {
  SupportExperience = 'support_experience',
  ProductReliability = 'product_reliability',
  CostAndValue = 'cost_and_value',
  ProductFeaturesAndFunctionality = 'product_features_and_functionality',
  UsabilityAndDesign = 'usability_and_design',
  Other = 'other',
}

export const getFormOptions = (
  t: TFunction,
): Record<
  REASON,
  {
    text: string;
    value: string;
    feedbackText?: string;
    subReasons: {
      text: string;
      value: string;
    }[];
  }
> => ({
  [REASON.SupportExperience]: {
    text: t('cancel_form.reason.support_experience', {
      ns: 'cancellation',
      defaultValue: 'Support Experience',
    }),
    value: REASON.SupportExperience,
    feedbackText: t('cancel_form.reason.support_experience_feedback_text', {
      ns: 'cancellation',
      defaultValue:
        'Tell us where our support could do better: Was it the wait, the clarity, the outcome?',
    }),
    subReasons: [
      {
        text: t('cancel_form.reason.support_experience_tested_sub_reason[0]', {
          ns: 'cancellation',
          defaultValue: 'Support was slow or unresponsive',
        }),
        value: 'support_was_slow_or_unresponsive',
      },
      {
        text: t('cancel_form.reason.support_experience_tested_sub_reason[1]', {
          ns: 'cancellation',
          defaultValue: 'Guidance provided was unhelpful',
        }),
        value: 'guidance_provided_was_unhelpful',
      },
      {
        text: t('cancel_form.reason.support_experience_tested_sub_reason[2]', {
          ns: 'cancellation',
          defaultValue: "My issue wasn't resolved",
        }),
        value: 'my_issue_was not resolved',
      },
    ],
  },
  [REASON.ProductReliability]: {
    text: t('cancel_form.reason.product_reliability', {
      ns: 'cancellation',
      defaultValue: 'Product Reliability',
    }),
    value: REASON.ProductReliability,
    feedbackText: t('cancel_form.reason.product_reliability_feedback_text', {
      ns: 'cancellation',
      defaultValue:
        'What tech hiccups did you face: errors, speed or downtime?',
    }),
    subReasons: [
      {
        text: t('cancel_form.reason.product_reliability_sub_reason[0]', {
          ns: 'cancellation',
          defaultValue: 'There were frequent bugs or glitches',
        }),
        value: 'bugs_or_glitches',
      },
      {
        text: t('cancel_form.reason.product_reliability_sub_reason[1]', {
          ns: 'cancellation',
          defaultValue: 'Performance was slow or unreliable',
        }),
        value: 'slow_performance_unreliable',
      },
      {
        text: t('cancel_form.reason.product_reliability_sub_reason[2]', {
          ns: 'cancellation',
          defaultValue: 'It was difficult to get started with the product',
        }),
        value: 'difficult_start_with_product',
      },
    ],
  },
  [REASON.CostAndValue]: {
    text: t('cancel_form.reason.cost_and_value', {
      ns: 'cancellation',
      defaultValue: 'Cost and Value',
    }),
    value: REASON.CostAndValue,
    feedbackText: t('cancel_form.reason.cost_and_value_feedback_text', {
      ns: 'cancellation',
      defaultValue:
        'Help us understand the value issue: Was it budget, alternatives, usage, or perceived value?',
    }),
    subReasons: [
      {
        text: t('cancel_form.reason.cost_and_value_sub_reason[0]', {
          ns: 'cancellation',
          defaultValue: 'The product was too expensive',
        }),
        value: 'too_expensive',
      },
      {
        text: t('cancel_form.reason.cost_and_value_sub_reason[1]', {
          ns: 'cancellation',
          defaultValue: "It didn't deliver enough value for the price",
        }),
        value: 'not_enough_value_for_the_price',
      },
      {
        text: t('cancel_form.reason.cost_and_value_sub_reason[2]', {
          ns: 'cancellation',
          defaultValue: 'There were issues with billing or subscription',
        }),
        value: 'billing_subscription_issues',
      },
      {
        text: t('cancel_form.reason.cost_and_value_sub_reason[3]', {
          ns: 'cancellation',
          defaultValue: "The trial period didn't meet my expectations",
        }),
        value: 'trial_not_meet_expectations',
      },
    ],
  },
  [REASON.ProductFeaturesAndFunctionality]: {
    text: t('cancel_form.reason.product_feature_and_functionality', {
      ns: 'cancellation',
      defaultValue: 'Product Features and Functionality',
    }),
    value: REASON.ProductFeaturesAndFunctionality,
    feedbackText: t(
      'cancel_form.reason.product_feature_and_functionality_feedback_text',
      {
        ns: 'cancellation',
        defaultValue:
          'Tell us where which feature fell short? Was something missing?',
      },
    ),
    subReasons: [
      {
        text: t(
          'cancel_form.reason.product_feature_and_functionality_sub_reason[0]',
          {
            ns: 'cancellation',
            defaultValue: 'The product was missing key features',
          },
        ),
        value: 'missing_key_and_features',
      },
      {
        text: t(
          'cancel_form.reason.product_feature_and_functionality_sub_reason[1]',
          {
            ns: 'cancellation',
            defaultValue: 'It needed more customization options',
          },
        ),
        value: 'needed_more_customization_options',
      },
      {
        text: t(
          'cancel_form.reason.product_feature_and_functionality_sub_reason[2]',
          {
            ns: 'cancellation',
            defaultValue: "It didn't integrate well with other tools",
          },
        ),
        value: 'bad_integration_with_other_tools',
      },
      {
        text: t(
          'cancel_form.reason.product_feature_and_functionality_sub_reason[3]',
          {
            ns: 'cancellation',
            defaultValue: "The product didn't meet my goals",
          },
        ),
        value: 'product_did_not_meet_my_goals',
      },
    ],
  },
  [REASON.UsabilityAndDesign]: {
    text: t('cancel_form.reason.usability_and_design', {
      ns: 'cancellation',
      defaultValue: 'Usability and Design',
    }),
    value: REASON.ProductFeaturesAndFunctionality,
    feedbackText: t(
      'cancel_form.reason.product_usability_and_design_feedback_text',
      {
        ns: 'cancellation',
        defaultValue:
          'Was it easy to get around? Tell us about navigation, design, accessibility, or layout preferences',
      },
    ),
    subReasons: [
      {
        text: t('cancel_form.reason.usability_and_design_sub_reason[0]', {
          ns: 'cancellation',
          defaultValue: 'The product was difficult to use',
        }),
        value: 'difficult_to_use',
      },
      {
        text: t('cancel_form.reason.usability_and_design_sub_reason[1]', {
          ns: 'cancellation',
          defaultValue: 'The design or layout was unappealing',
        }),
        value: 'unappealing_design_or_layout',
      },
      {
        text: t('cancel_form.reason.usability_and_design_sub_reason[2]', {
          ns: 'cancellation',
          defaultValue: 'There were issues with language support',
        }),
        value: 'issues_with_language_support',
      },
    ],
  },
  [REASON.Other]: {
    text: t('general:other', 'Other'),
    value: REASON.Other,
    subReasons: [],
  },
});
