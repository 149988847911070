import React, { FC } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSwiper } from 'swiper/react';

import { PAYMENT_METHODS } from '~/types/payment';

import { ReactComponent as CardsIcon } from './assets/cards_payment.svg';
import { ReactComponent as OneClickPay } from './assets/one_click_payment.svg';
import styles from './styles.module.scss';

interface Props {
  isActive: boolean;
  onClick: (type: PAYMENT_METHODS) => void;
  type: PAYMENT_METHODS;
}

const PaymentButton: FC<Props> = ({ isActive, onClick, type }: Props) => {
  const { t } = useTranslation('general');
  const swiper = useSwiper();

  const handleClick = (): void => {
    onClick(type);
    type === PAYMENT_METHODS.CARD ? swiper.slidePrev() : swiper.slideNext();
  };

  return (
    <button
      type="button"
      className={clsx(styles.card, {
        [styles.is_active]: isActive,
      })}
      onClick={handleClick}
    >
      <div className={styles.card__text}>
        {type === PAYMENT_METHODS.CARD
          ? t('credit_card', 'Credit Card')
          : t('one_click_pay', 'One-Click Pay')}
      </div>

      {type === PAYMENT_METHODS.CARD ? <CardsIcon /> : <OneClickPay />}
    </button>
  );
};

export default PaymentButton;
