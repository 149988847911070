import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import ModalContentText from '~/components/atoms/ModalContentText';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
};

const SubscriptionCancelRequiredModal: FC<Props> = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation('modal');

  return (
    <div>
      <ModalContentTitle
        text={t(
          'sub_cancel_required.title',
          'Subscription cancellation required',
        )}
      />

      <ModalContentText sx={{ mb: '32px' }}>
        {t(
          'sub_cancel_required.text',
          'Before deletion of your data you need to cancel your active subscription',
        )}
      </ModalContentText>

      <div>
        <Button onClick={onCancel} variant="contained">
          {t('dismiss', { ns: 'general', defaultValue: 'Dismiss' })}
        </Button>

        <Button onClick={onSubmit} variant="text">
          {t('sub_cancel_required.submit_button', 'Cancel my subscription')}
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionCancelRequiredModal;
