import { TFunction } from 'i18next';

export const getCategoryName = (
  category_id: string,
  category_name: string,
  t: TFunction,
): string => t(`dynamic-categories:${category_id}`, category_name) as string;

export const getToolTitle = (
  tool_id: string,
  tool_name: string,
  t: TFunction,
): string => t(`dynamic-tools:${tool_id}.name`, tool_name) as string;

export const getToolDescription = (
  tool_id: string,
  tool_description: string,
  t: TFunction,
): string =>
  t(`dynamic-tools:${tool_id}.description`, tool_description) as string;

export const getToolFieldLabel = (
  tool_id: string,
  field_name: string,
  label: string,
  t: TFunction,
): string =>
  t(`dynamic-tools:${tool_id}.fields.${field_name}.label`, label) as string;

export const getToolFieldPlaceholder = (
  tool_id: string,
  field_name: string,
  placeholder: string,
  t: TFunction,
): string =>
  t(
    `dynamic-tools:${tool_id}.fields.${field_name}.placeholder`,
    placeholder,
  ) as string;

export const getToolFieldSelectionOptionText = (
  tool_id: string,
  field_name: string,
  option_text: string,
  t: TFunction,
): string =>
  t(
    `dynamic-tools:${tool_id}.fields.${field_name}.options.${option_text}`,
    option_text,
  ) as string;

export const getProductName = (
  product_id: string,
  product_name: string,
  t: TFunction,
): string => t(`dynamic-products:${product_id}.name`, product_name) as string;
