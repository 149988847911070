import { TFunction } from 'i18next';

const inputStyles = {
  '.error input': {
    border: '2px solid #E15E5E',
    background: 'rgba(225, 94, 94, 0.1)',
  },
  '.error .label': {
    color: '#2C3246',
    '::after': {
      content: 'none',
    },
  },
  '.error-text': {
    'z-index': 4,
    background: '#E15E5E',
    color: 'white',
    'border-radius': '6px',
    'font-weight': '500',
    'font-size': '10px',
    padding: '6px 12px',
    'text-align': 'center',
    margin: '0 auto',
    top: '46px',
    left: '50%',
    transform: 'translate(-50%)',
    'min-width': '150px',
    'line-height': '150%',
    'letter-spacing': '-0.2px',
    position: 'absolute',
  },
  '.triangle': {
    content: '',
    position: 'absolute',
    top: '-6px',
    left: '50%',
    transform: 'translate(-50%)',
    width: 0,
    height: 0,
    margin: '0 auto',
    'border-style': 'solid',
    'border-width': '0 6px 8px',
    'border-color': 'transparent transparent #E15E5E',
    '.text': {
      display: 'none',
    },
  },

  input: {
    border: '2px solid #E8ECEF',
    'border-radius': '12px 12px 0px 0px',
    padding: '20px',
    height: '60px',
    'font-size': '16px',
    color: '#181B29',
    'font-weight': 500,
    'font-family': 'Inter, sans-serif',

    ':focus': {
      padding: '20px',
      'border-color': '#5EBE7D',
      background: 'rgba(94, 190, 125, 0.1)',
      color: '#000000',
    },

    '::placeholder': {
      color: 'rgba(108, 114, 117, 0.50)',
    },
  },

  '.label': {
    display: 'none',
  },

  '.brand-icon': {
    top: '50%',
    transform: 'translateY(-50%)',
    left: '20px',
    right: 'unset',
  },
};

export const sliderFormStyles = {
  form_body: {
    'form-container': {
      'padding-top': '0',
    },

    '.two-columns': {
      margin: 0,
      'grid-template-columns': 'repeat(2,calc(50%))',
      'grid-column-gap': '0',

      input: {
        'border-radius': '0px 0px 12px 0px',
      },

      '.expiry_date': {
        input: {
          'border-radius': '0px 0px 0px 12px',
        },
      },
    },
  },

  card_number: {
    ...inputStyles,

    '.error-text': {
      'z-index': 4,
      background: '#E15E5E',
      color: 'white',
      'border-radius': '6px',
      'font-weight': '500',
      'font-size': '11px',
      padding: '6px 12px',
      'text-align': 'center',
      margin: '0 auto',
      top: '46px',
      left: '50%',
      transform: 'translate(-50%)',
      'min-width': '280px',
      'line-height': '150%',
      'letter-spacing': '-0.2px',
      position: 'absolute',
    },
  },

  expiry_date: {
    ...inputStyles,
  },

  card_cvv: {
    ...inputStyles,

    '.tooltip-icon': {
      display: 'none',
    },
  },

  submit_button: {
    'border-radius': '100px',
    display: 'block',
    padding: '18px',
    margin: '24px 0 0 0',
    height: 'auto',
    'background-color': '#254699',
    cursor: 'pointer',
    'pointer-events': 'all',
    '.title-icon': {
      display: 'none',
    },
    '.title': {
      'text-transform': 'capitalize',
      'font-weight': '500',
      'font-family': 'Inter, sans-serif',
      'font-size': '14px',
      'line-height': '20px',
    },
  },
};

export const sliderFormParams = (
  t: TFunction,
): { submitButtonText: string; cardExpiryDatePlaceholder: string } => {
  return {
    submitButtonText: t('general:continue', 'Continue'),
    cardExpiryDatePlaceholder: 'MM/YY',
  };
};
