import React, { FC, useEffect } from 'react';

import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import ModalContentText from '~/components/atoms/ModalContentText';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import { colors } from '~/theme/colors';

const SIGN_OUT_TIMEOUT = 10000; // 10 sec

let timeout: number | null = null;

type Props = {
  onClickGotIt: () => void;
  signOut: () => void;
  closeModal: () => void;
};

const DeleteAllDataSuccessModal: FC<Props> = ({
  onClickGotIt,
  signOut,
  closeModal,
}) => {
  const { t } = useTranslation('modal');

  useEffect(() => {
    timeout = window.setTimeout(() => {
      closeModal();
      signOut();
    }, SIGN_OUT_TIMEOUT);
  }, []);

  const handleClickSignOut = (): void => {
    timeout && clearTimeout(timeout);
    closeModal();
    signOut();
  };

  return (
    <div>
      <ModalContentTitle
        text={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon
              style={{ color: colors.green_50 }}
              icon="material-symbols:check"
            />
            &nbsp;&nbsp;
            <span>
              {t(
                'delete_all_data_success.title',
                'Account Deletion is confirmed',
              )}
            </span>
          </div>
        }
      />

      <ModalContentText
        sx={{
          marginBottom: '40px',
        }}
      >
        {t(
          'delete_all_data_success.text',
          'You will be logged out automatically in 10 seconds and your account will be deleted',
        )}
      </ModalContentText>

      <div>
        <Button onClick={onClickGotIt} variant="contained">
          {t('general:got_it', 'Got it')}{' '}
        </Button>
        <Button onClick={handleClickSignOut} variant="text">
          {t('delete_all_data_success.logout_button', 'Log out now')}
        </Button>
      </div>
    </div>
  );
};

export default DeleteAllDataSuccessModal;
