import React, {
  FC,
  forwardRef,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { Icon } from '@iconify/react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import IconButton from '~/components/atoms/buttons/IconButton';

import styles from './styles.module.scss';

const Messages: FC<{
  message: ReactElement | string;
  optionalMessage?: ReactElement | string;
}> = ({ message, optionalMessage }) => {
  return (
    <div>
      <div className={styles.message}>{message}</div>
      {optionalMessage && (
        <div className={styles.optional_message}>{message}</div>
      )}
    </div>
  );
};

const ReportButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation('general');

  return (
    <Button
      onClick={onClick}
      className={styles.report_button}
      size="extraSmall"
      variant="text"
    >
      {t('general:report_issue', 'Report Issue')}
    </Button>
  );
};

const CloseButton: FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <IconButton onClick={onClose} className={styles.close_button} size="small">
      <Icon icon="material-symbols:close" className={styles.close_icon} />
    </IconButton>
  );
};

const SuccessSnackbar: FC<{
  message: ReactElement | string;
  optionalMessage?: ReactElement | string;
}> = ({ message, optionalMessage }) => {
  return (
    <>
      <Icon icon="material-symbols:check" className={styles.success_icon} />
      <Messages message={message} optionalMessage={optionalMessage} />
    </>
  );
};

const ReportSnackbar: FC<{
  onClose: () => void;
  onSubmit: () => void;
  message: ReactElement | string;
  optionalMessage?: ReactElement | string;
  isReportButton: boolean;
}> = ({ onClose, onSubmit, message, optionalMessage, isReportButton }) => {
  const { t } = useTranslation('notification');
  const [isReportSent, setIsReportSent] = useState(false);

  const handleReportClick = (): void => {
    onSubmit();
    setIsReportSent(true);
  };

  const handleReportClose = (): void => {
    setIsReportSent(true);
    onClose();
  };

  return (
    <div
      className={clsx(styles.report_outer_block, {
        [styles.is_sent]: isReportSent,
      })}
    >
      {isReportSent && (
        <Icon icon="material-symbols:check" className={styles.forward_icon} />
      )}
      <Messages
        message={
          isReportSent ? (
            <Trans i18nKey="report_received" t={t}>
              Report received. We'll fix it soon. <br /> Thanks!
            </Trans>
          ) : (
            <>{message}</>
          )
        }
        optionalMessage={optionalMessage}
      />
      <div className={styles.report_inner_block}>
        {!isReportSent && isReportButton && (
          <ReportButton onClick={handleReportClick} />
        )}
        <CloseButton onClose={handleReportClose} />
      </div>
    </div>
  );
};

interface Props {
  message: ReactElement | string;
  optionalMessage?: ReactElement | string;
  type: 'error' | 'success' | 'info' | 'system' | 'report';
  handleClose: () => void;
  handleSubmit: () => void;
  handleView: () => void;
  isFullWidth: boolean;
  isReportButton: boolean;
}

const Snackbar = forwardRef<HTMLDivElement, Props>(
  (
    {
      message,
      optionalMessage,
      type,
      handleClose,
      handleSubmit,
      handleView,
      isFullWidth,
      isReportButton,
    },
    ref,
  ) => {
    useEffect(() => {
      if (handleView) {
        handleView();
      }
    }, []);

    const renderSnack = (): JSX.Element => {
      switch (type) {
        case 'report':
          return (
            <ReportSnackbar
              onClose={handleClose}
              onSubmit={handleSubmit}
              message={message}
              optionalMessage={optionalMessage}
              isReportButton={isReportButton}
            />
          );

        case 'success':
          return (
            <SuccessSnackbar
              message={message}
              optionalMessage={optionalMessage}
            />
          );

        default:
          return (
            <div className={styles.flex_wrapper}>
              <Messages message={message} optionalMessage={optionalMessage} />
              <CloseButton onClose={handleClose} />
            </div>
          );
      }
    };

    return (
      <div
        ref={ref}
        className={clsx(styles.card, styles[type], {
          [styles.full_width]: isFullWidth,
        })}
      >
        {renderSnack()}
      </div>
    );
  },
);

export default Snackbar;
