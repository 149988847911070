import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

const getBenefits = (
  t: TFunction,
): {
  emoji: string;
  text: string;
  value: string;
}[] => [
  {
    emoji: '✍️',
    text: t('general:words_generated', 'Words Generated'),
    value: '10,000+',
  },
  {
    emoji: '📍',
    text: t('general:tasks_completed', 'Tasks Completed'),
    value: '500+',
  },
  {
    emoji: '🕗',
    text: t('general:hours_saved', 'Hours Saved'),
    value: '80+ ',
  },
];

const StaticUserBenefits: FC = () => {
  const { t } = useTranslation('general');
  return (
    <div className={styles.benefits}>
      {getBenefits(t).map((ben) => (
        <Typography
          className={styles.benefit}
          variant={Typographies.BODY_LARGE}
          component="p"
          key={ben.value}
        >
          <span className={styles.benefit__emoji}>{ben.emoji}</span>
          <span className={styles.benefit__text}>{ben.text}</span>
          <span className={styles.benefit__value}>{ben.value}</span>
        </Typography>
      ))}
    </div>
  );
};

export default StaticUserBenefits;
