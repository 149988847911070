import router, { RouteItemType } from '~/core/router';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';
import {
  getOnboardingTitle,
  OnboardingPath,
} from '~/modules/onboarding-new/constants';

const Onboarding = lazyComponentLoader(() => import('./components/Index'));

const OnboardingRoute: RouteItemType = {
  Component: Onboarding,
  path: OnboardingPath,
  getTitle: getOnboardingTitle,
};

router.addPrivateRoutes([OnboardingRoute]);
