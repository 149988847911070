import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { SubscriptionPath } from '~/modules/settings/constants';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  onClick: () => void;
};

const ViewAllPlansLink: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation('settings');

  return (
    <Typography
      className={styles.container}
      variant={Typographies.BODY_MEDIUM}
      component="p"
    >
      <Trans i18nKey="subscription.view_all_plans" t={t}>
        Need more?
        <br />
        <a href={SubscriptionPath} className={styles.link} onClick={onClick}>
          View all plans 🚀
        </a>
      </Trans>
    </Typography>
  );
};

export default ViewAllPlansLink;
