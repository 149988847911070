import React, { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import { SupportCases } from '~/components/molecules/SupportModal';
import SupportCaseOption from '~/components/molecules/SupportModal/components/SupportCaseOption';
import useDeleteAccount from '~/modules/settings/hooks/useDeleteAccount';
import analytics from '~/services/analytics';

import commonStyles from '../../styles.module.scss';

type Props = {
  onClose: () => void;
  onNext: (nextCase: SupportCases) => void;
  onBack: () => void;
};

const AccountDeletionCase: FC<Props> = ({ onClose, onNext, onBack }) => {
  const { t } = useTranslation('modal');
  const { showDeleteAccountModal } = useDeleteAccount();

  const handleDeleteAccount = (): void => {
    analytics.trackEvent('self-support - click need removal');
    showDeleteAccountModal();
  };

  const handleClickOther = (): void => {
    analytics.trackEvent('self-support - click other');
    onNext(SupportCases.OTHER);
  };

  useEffect(() => {
    analytics.trackEvent('self-support - data removal view');
  }, []);

  return (
    <div>
      <ModalContentTitle
        text={t('support.title', 'How can we help you?')}
        className={commonStyles.title}
      />

      <div className={commonStyles.options}>
        <SupportCaseOption onClick={handleDeleteAccount}>
          {t('support.deletion', 'I want to delete my account')}
        </SupportCaseOption>

        <SupportCaseOption onClick={handleClickOther}>
          {t('support.other', 'I have other questions')}
        </SupportCaseOption>
      </div>

      <div className={commonStyles.buttons_container}>
        <Button color="primary" variant="contained" onClick={onClose}>
          {t('general:done', 'Done')}
        </Button>

        <Button color="primary" variant="text" onClick={onBack}>
          {t('general:back', 'Back')}
        </Button>
      </div>
    </div>
  );
};

export default AccountDeletionCase;
