import React, { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import { SupportCases } from '~/components/molecules/SupportModal';
import SupportCaseOption from '~/components/molecules/SupportModal/components/SupportCaseOption';
import analytics from '~/services/analytics';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { SUBSCRIPTION_STATUS } from '~/types/subscription';

import commonStyles from '../../styles.module.scss';

type Props = {
  onNext: (nextCase: SupportCases) => void;
};

const EntryCase: FC<Props> = ({ onNext }) => {
  const { t } = useTranslation('modal');
  const { user_subscription } = useAppSelector((state) => state.profile);

  const handleClickOther = (): void => {
    analytics.trackEvent('self-support - click other');
    onNext(SupportCases.OTHER);
  };

  useEffect(() => {
    analytics.trackEvent('self-support - main view');
  }, []);

  return (
    <div>
      <ModalContentTitle
        text={t('support.title', 'How can we help you?')}
        className={commonStyles.title}
      />

      <div className={commonStyles.options}>
        {![
          SUBSCRIPTION_STATUS.CANCELLED,
          SUBSCRIPTION_STATUS.CANCEL_PENDING,
        ].includes(user_subscription.status) && (
          <SupportCaseOption onClick={() => onNext(SupportCases.CANCELLATION)}>
            {t(
              'general:subscription_cancellation',
              'Subscription Cancellation',
            )}
          </SupportCaseOption>
        )}

        <SupportCaseOption
          onClick={() => onNext(SupportCases.ACCOUNT_DELETION)}
        >
          {t('general:account_deletion', 'Account Deletion')}
        </SupportCaseOption>

        <SupportCaseOption onClick={handleClickOther}>
          {t('general:other', 'Other')}
        </SupportCaseOption>
      </div>
    </div>
  );
};

export default EntryCase;
