export const SignInPath = '/sign-in';
export const getSignInTitle = (): string => 'Log In';

export const SignUpPath = '/sign-up';
export const getSignUpTitle = (): string => 'Sign Up';

export const ForgotPasswordPath = '/forgot-password';

export const getForgotPasswordTitle = (): string => 'Forgot Password';

export const ResetPasswordPath = '/reset-password';

export const getResetPasswordTitle = (): string => 'Reset Password';
