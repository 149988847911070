import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import AttentionImg from '~/assets/images/attention.webp';
import AttentionPreviewImg from '~/assets/images/attention_preview.png';
import Button from '~/components/atoms/buttons/Button';
import LoadingButton from '~/components/atoms/buttons/LoadingButton';
import Image from '~/components/atoms/Image';
import ModalContentText from '~/components/atoms/ModalContentText';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import analytics from '~/services/analytics';

import styles from './styles.module.scss';

type Props = {
  onSubmit: () => Promise<void>;
  onClose: () => void;
};

const RedemptSub: FC<Props> = ({ onSubmit, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('modal');

  const handleSubmit = async (): Promise<void> => {
    try {
      analytics.trackEvent('subscription - redemption fix click');
      setIsLoading(true);
      await onSubmit();
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefuse = (): void => {
    analytics.trackEvent('subscription - close redemption screen');
    onClose();
  };

  useEffect(() => {
    analytics.trackEvent('subscription - redemption screen');
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Image src={AttentionImg} preview={AttentionPreviewImg} />
      </div>

      <ModalContentTitle
        className={styles.title}
        text={t('general:payment_issue', 'Payment Issue')}
      />

      <ModalContentText sx={{ mb: '32px', maxWidth: 'unset' }}>
        {t(
          'redempt_sub.text',
          "We couldn't process your payment. Please click the button below to fix it.",
        )}
      </ModalContentText>

      <div className={styles.buttons_container}>
        <LoadingButton
          className={styles.button}
          disabled={isLoading}
          loading={isLoading}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          {t('general:fix_my_payment', 'Fix My Payment')}
        </LoadingButton>

        <Button
          className={styles.button}
          disabled={isLoading}
          variant="text"
          color="primary"
          onClick={handleRefuse}
        >
          {t('general:no_thanks', 'No, thanks')}
        </Button>
      </div>
    </div>
  );
};

export default RedemptSub;
