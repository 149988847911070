import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';

const Guarantee: FC = () => {
  const { t } = useTranslation('subscription');
  return (
    <div className={styles.guarantee}>
      <div className={styles.icon}>
        <LockIcon />
      </div>

      <Typography
        className={styles.text}
        variant={Typographies.BODY_MEDIUM}
        component="p"
      >
        {t('payment_form.guarantee', 'Guaranteed security payments')}
      </Typography>
    </div>
  );
};

export default Guarantee;
