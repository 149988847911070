import React, { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import ModalContentText from '~/components/atoms/ModalContentText';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import { SUPPORT_EMAIL } from '~/constants';
import analytics from '~/services/analytics';

import commonStyles from '../../styles.module.scss';

type Props = {
  onClose: () => void;
  onBack?: () => void;
};

const OtherCase: FC<Props> = ({ onClose, onBack }) => {
  const { t } = useTranslation('modal');

  useEffect(() => {
    analytics.trackEvent('self-support - support info');
  }, []);

  return (
    <div>
      <ModalContentTitle
        text={t('general:contact_support', 'Contact Support')}
        className={commonStyles.title}
      />
      <ModalContentText>
        Reach out to our support team for quick help. Contact us at{' '}
        <a
          className={commonStyles.link}
          href={`mailto:${SUPPORT_EMAIL}`}
          target="_blank"
          rel="noreferrer nooperner"
          onClick={(): void =>
            analytics.trackEvent('support - click support email')
          }
        >
          {SUPPORT_EMAIL}
        </a>
      </ModalContentText>

      <div className={commonStyles.buttons_container}>
        <Button color="primary" variant="contained" onClick={onClose}>
          {t('general:done', 'Done')}
        </Button>

        {onBack && (
          <Button color="primary" variant="text" onClick={onBack}>
            {t('general:back', 'Back')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default OtherCase;
