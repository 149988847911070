import { FC } from 'react';

import { TFunction } from 'i18next';

import { ProfileType } from '~/types/profile';

export type SideBarItemType = {
  key: string;
  path: string;
  redirectPath?: string;
  Icon: FC<{ isActive?: boolean }>;
  getTitle: (t: TFunction) => string;
  getLabel?: (t: TFunction) => string;
  checkAvailability?: (profile: ProfileType) => boolean;
  order?: number;
};

class Sidebar {
  private items: Record<string, SideBarItemType[]>;
  private callbacks: Array<
    (groupedItems: Record<string, SideBarItemType[]>) => void
  >;

  constructor(routes?: Record<string, SideBarItemType[]>) {
    this.items = routes ?? {};
    this.callbacks = [];
  }

  addItems(routes: Record<string, SideBarItemType[]>): void {
    Object.entries(routes).forEach(([key, value]) => {
      if (this.items[key]) {
        this.items[key] = [...this.items[key], ...value];
      } else {
        this.items[key] = value;
      }
    });
  }

  getItems(): Record<string, SideBarItemType[]> {
    return this.items;
  }

  getAvailableItems(profile: ProfileType): Record<string, SideBarItemType[]> {
    return Object.fromEntries(
      Object.entries(this.items).map(([key, item]) => [
        key,
        item
          .filter(({ checkAvailability }) =>
            checkAvailability ? checkAvailability(profile) : true,
          )
          .sort((a, b) => (a.order && b.order ? a.order - b.order : 0)),
      ]),
    );
  }

  setLabel(keyToUpdate: string, getLabel: (t: TFunction) => string): void {
    this.items = Object.fromEntries(
      Object.entries(this.items).map(([key, items]) => [
        key,
        items.map((item) => ({
          ...item,
          getLabel: keyToUpdate === item.key ? getLabel : item.getLabel,
        })),
      ]),
    );
    this.callbacks.forEach((cb) => {
      cb(this.items);
    });
  }

  subscribe(
    callback: (groupedItems: Record<string, SideBarItemType[]>) => void,
  ): () => void {
    this.callbacks.push(callback);

    return () => {
      this.callbacks = this.callbacks.filter((cb) => cb !== callback);
    };
  }
}

export const sidebar = new Sidebar();
export const settingsSidebar = new Sidebar();
