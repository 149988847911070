import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getPaymentErrorText } from './errors';
import styles from './styles.module.scss';

interface Props {
  paymentError: any;
  handleCloseError: () => void;
}

const PaymentError: FC<Props> = ({ paymentError, handleCloseError }: Props) => {
  const { t } = useTranslation('general');
  return (
    <Dialog open={true} onClose={handleCloseError}>
      <div className={styles.wrapper}>
        <div className={styles.error_info}>
          <div className={styles.error_sign}>
            <Icon icon="ion:warning-outline" />
          </div>
          <div className={styles.error_text}>
            {getPaymentErrorText(paymentError, t)}
          </div>
        </div>
        <button
          className={styles.ok_button}
          type="button"
          onClick={handleCloseError}
        >
          {t('ok', 'ok')}
        </button>
      </div>
    </Dialog>
  );
};

export default PaymentError;
