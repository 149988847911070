import React from 'react';

import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

import languages from '~/constants/languages.json';
import { CardConstructorType } from '~/modules/onboarding-new/Card';
import Template1 from '~/modules/onboarding-new/cardTemplates/Template1';
import Template2 from '~/modules/onboarding-new/cardTemplates/Template2';
import Template3 from '~/modules/onboarding-new/cardTemplates/Template3';
import { TemplateVariantProps } from '~/modules/onboarding-new/types';
import analytics from '~/services/analytics';

export const OnboardingPath = '/onboarding';

export const getOnboardingTitle = (): string => 'Onboarding'; // TODO translate ?

const predefinedLanguages = [
  'English',
  'Spanish',
  'Portuguese',
  'German',
  'French',
  'Chinese',
  'Arabic',
];

const browserLangCode = navigator.language.split('-')[0];
const browserLanguage =
  languages.find(({ code }) => code === browserLangCode)?.language || 'English';

const prioritizedLanguages = [
  ...new Set([browserLanguage, ...predefinedLanguages]),
];

const LANGUAGE_LIST = languages
  .map(({ language }) => language)
  .sort((a, b) => {
    const indexA = prioritizedLanguages.indexOf(a);
    const indexB = prioritizedLanguages.indexOf(b);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    if (indexA !== -1) {
      return -1;
    }

    if (indexB !== -1) {
      return 1;
    }

    return a.localeCompare(b);
  })
  .map((lang) => ({
    getText: (t: TFunction): string =>
      t(`languages:${lang.toLowerCase()}`, lang),
    value: lang,
  }));

export enum ONBOARDING_CARDS {
  PROFESSION = 'profession',
  EDUCATION_LEVEL = 'education_level',
  FIELD_OF_STUDY = 'field_of_study',
  POSITION = 'position',
  COMPANY_SIZE = 'company_size',
  LANGUAGE = 'language',
  AI_EXPERIENCE = 'ai_experience',
}

const professions = [
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.student', 'Student'),
    value: 'Student',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.teacher_educator', 'Teacher/Educator'),
    value: 'Teacher/Educator',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.researcher_academic', 'Researcher/Academic'),
    value: 'Researcher/Academic',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.content_writer', 'Content Writer'),
    value: 'Content Writer',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.author', 'Author'),
    value: 'Author',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.copywriter', 'Copywriter'),
    value: 'Copywriter',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.accountant', 'Accountant'),
    value: 'Accountant',
  },
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:profession.healthcare_professional',
        'Healthcare Professional',
      ),
    value: 'Healthcare Professional',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.manager_executive', 'Manager/Executive'),
    value: 'Manager/Executive',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.sales_manager', 'Sales Manager'),
    value: 'Sales Manager',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.it_specialist', 'IT Specialist'),
    value: 'IT Specialist',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.marketing_specialist', 'Marketing Specialist'),
    value: 'Marketing Specialist',
  },
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:profession.customer_service_specialist',
        'Customer Service Specialist',
      ),
    value: 'Customer Service Specialist',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.lawyer', 'Lawyer'),
    value: 'Lawyer',
  },
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:profession.project_product_manager',
        'Project/Product Manager',
      ),
    value: 'Project/Product Manager',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.data_analyst', 'Data Analyst'),
    value: 'Data Analyst',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.designer', 'Designer'),
    value: 'Designer',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.software_developer', 'Software Developer'),
    value: 'Software Developer',
  },
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:profession.human_resources_recruiter',
        'Human Resources/Recruiter',
      ),
    value: 'Human Resources/Recruiter',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.journalist', 'Journalist'),
    value: 'Journalist',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.engineer', 'Engineer'),
    value: 'Engineer',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.artist', 'Artist'),
    value: 'Artist',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.scientist', 'Scientist'),
    value: 'Scientist',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.finance_professional', 'Finance Professional'),
    value: 'Finance Professional',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:profession.social_worker', 'Social Worker'),
    value: 'Social Worker',
  },
];

const educationalLevels = [
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:educational_level.high_secondary_school',
        'Still in High/Secondary School',
      ),
    value: 'Still in High/Secondary School',
  },
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:educational_level.high_school_diploma',
        'High School Diploma',
      ),
    value: 'High School Diploma',
  },
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:educational_level.associates_college_degree',
        'Associate’s/College Degree',
      ),
    value: 'Associate’s/College Degree',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:educational_level.bachelors_degree', 'Bachelor’s Degree'),
    value: 'Bachelor’s Degree',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:educational_level.masters_degree', 'Master’s Degree'),
    value: 'Master’s Degree',
  },
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:educational_level.doctoral_degree',
        'Doctoral Degree (PhD, EdD, etc.)',
      ),
    value: 'Doctoral Degree (PhD, EdD, etc.)',
  },
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:educational_level.professional_degree',
        'Professional Degree (MD, JD, DDS, etc.)',
      ),
    value: 'Professional Degree (MD, JD, DDS, etc.)',
  },
];

const fieldsOfStudy = [
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:field_of_study.business_management',
        'Business & Management',
      ),
    value: 'Business & Management',
  },
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:field_of_study.computer_information_science',
        'Computer & Information Science',
      ),
    value: 'Computer & Information Science',
  },
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:field_of_study.engineering_technology',
        'Engineering & Technology',
      ),
    value: 'Engineering & Technology',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:field_of_study.healthcare_medical', 'Healthcare & Medical'),
    value: 'Healthcare & Medical',
  },
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:field_of_study.natural_physical_sciences',
        'Natural & Physical Sciences',
      ),
    value: 'Natural & Physical Sciences',
  },
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:field_of_study.mathematics_statistics',
        'Mathematics & Statistics',
      ),
    value: 'Mathematics & Statistics',
  },
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:field_of_study.humanities_languages',
        'Humanities & Languages',
      ),
    value: 'Humanities & Languages',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:field_of_study.social_sciences', 'Social Sciences'),
    value: 'Social Sciences',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:field_of_study.law', 'Law'),
    value: 'Law',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:field_of_study.education_teaching', 'Education & Teaching'),
    value: 'Education & Teaching',
  },
  {
    getText: (t: TFunction): string =>
      t('onboarding:field_of_study.arts_design_media', 'Arts, Design & Media'),
    value: 'Arts, Design & Media',
  },
  {
    getText: (t: TFunction): string =>
      t(
        'onboarding:field_of_study.agriculture_environmental_studies',
        'Agriculture & Environmental Studies',
      ),
    value: 'Agriculture & Environmental Studies',
  },
];

const workPositions = [
  {
    getText: (t: TFunction): string => t('general:team_member', 'Team Member'),
    value: 'Team Member',
  },
  {
    getText: (t: TFunction): string => t('general:team_lead', 'Team Lead'),
    value: 'Team Lead',
  },
  {
    getText: (t: TFunction): string => t('general:executive', 'Executive'),
    value: 'Executive',
  },
];

const companySizes = [
  { getText: (): string => '1-50', value: '1-50' },
  { getText: (): string => '51-100', value: '51-100' },
  { getText: (): string => '100+', value: '100+' },
];

const aiExperiences = [
  {
    getText: (t: TFunction): string => t('general:profound', 'Profound'),
    value: 'Profound',
  },
  {
    getText: (t: TFunction): string =>
      t('general:used_couple_times', 'Used Couple Times'),
    value: 'Used Couple Times',
  },
  {
    getText: (t: TFunction): string => t('general:not_used', 'Not Used'),
    value: 'Not Used',
  },
];

export const CARD_CONFIG: Record<
  ONBOARDING_CARDS,
  CardConstructorType<TemplateVariantProps>
> = {
  [ONBOARDING_CARDS.PROFESSION]: {
    key: ONBOARDING_CARDS.PROFESSION,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose profession', { value }),
    Template: Template2,
    templateProps: {
      variants: professions.map((el) => {
        return {
          ...el,
          nextCard:
            el.value === 'Student'
              ? ONBOARDING_CARDS.EDUCATION_LEVEL
              : ONBOARDING_CARDS.POSITION,
        };
      }),
      Text: ({ children }) => (
        <Trans
          i18nKey="onboarding:card.profession"
          components={{ children: <>{children}</> }}
        >
          In my profession I'm a {'<children />'}
        </Trans>
      ),
      nextCard: ONBOARDING_CARDS.POSITION,
      isWithDot: true,
    },
  },
  [ONBOARDING_CARDS.EDUCATION_LEVEL]: {
    key: ONBOARDING_CARDS.EDUCATION_LEVEL,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose educational level', {
        value,
      }),
    Template: Template2,
    templateProps: {
      variants: educationalLevels.map((el) => {
        return {
          ...el,
          nextCard: ![
            'Still in High/Secondary School',
            'High School Diploma',
          ].includes(el.value)
            ? ONBOARDING_CARDS.FIELD_OF_STUDY
            : ONBOARDING_CARDS.LANGUAGE,
        };
      }),
      Text: ({ children }) => (
        <Trans
          i18nKey="onboarding:card.educational_level"
          components={{ children: <>{children}</> }}
        >
          What is the highest level of education you are currently studying for
          or have completed? {'<children />'}
        </Trans>
      ),
      nextCard: ONBOARDING_CARDS.FIELD_OF_STUDY,
      isWithDot: true,
    },
  },
  [ONBOARDING_CARDS.FIELD_OF_STUDY]: {
    key: ONBOARDING_CARDS.FIELD_OF_STUDY,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose field of study', {
        value,
      }),
    Template: Template2,
    templateProps: {
      variants: fieldsOfStudy,
      Text: ({ children }) => (
        <Trans
          i18nKey="onboarding:card.field_of_study"
          components={{ children: <>{children}</> }}
        >
          My field of study/major subject: {'<children />'}
        </Trans>
      ),
      nextCard: ONBOARDING_CARDS.LANGUAGE,
      isWithDot: true,
    },
  },
  [ONBOARDING_CARDS.POSITION]: {
    key: ONBOARDING_CARDS.POSITION,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose position', { value }),
    Template: Template1,
    templateProps: {
      variants: workPositions,
      Text: ({ children }) => (
        <Trans
          i18nKey="onboarding:card.position"
          components={{ children: <>{children}</> }}
        >
          More specifically, I'm a {'<children />'}
        </Trans>
      ),
      nextCard: ONBOARDING_CARDS.COMPANY_SIZE,
      isWithDot: false,
    },
  },
  [ONBOARDING_CARDS.COMPANY_SIZE]: {
    key: ONBOARDING_CARDS.COMPANY_SIZE,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose organization', { value }),
    Template: Template1,
    templateProps: {
      variants: companySizes,
      nextCard: ONBOARDING_CARDS.LANGUAGE,
      Text: ({ children }) => (
        <Trans
          i18nKey="onboarding:card.company_size"
          components={{ children: <>{children}</> }}
        >
          in a company of {'<children />'} employees
        </Trans>
      ),
      isWithDot: true,
    },
  },
  [ONBOARDING_CARDS.LANGUAGE]: {
    key: ONBOARDING_CARDS.LANGUAGE,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose language', { value }),
    Template: Template3,
    templateProps: {
      variants: LANGUAGE_LIST,
      Text: ({ children }) => (
        <Trans
          i18nKey="onboarding:card.language"
          components={{ children: <>{children}</> }}
        >
          My native language is {'<children />'}
        </Trans>
      ),
      nextCard: ONBOARDING_CARDS.AI_EXPERIENCE,
      isWithDot: true,
    },
  },
  [ONBOARDING_CARDS.AI_EXPERIENCE]: {
    key: ONBOARDING_CARDS.AI_EXPERIENCE,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose ai experience', { value }),
    Template: Template1,
    templateProps: {
      variants: aiExperiences,
      Text: ({ children }) => (
        <Trans
          i18nKey="onboarding:card.ai_experience"
          components={{ children: <>{children}</> }}
        >
          My experience with other AI tools (e.g ChatGPT, Claude, Gemini):{' '}
          <br /> {'<children />'}
        </Trans>
      ),
    },
    isLastCard: true,
  },
};
