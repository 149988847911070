import React, { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import Api from '~/Api';
import Button from '~/components/atoms/buttons/Button';
import ModalContentText from '~/components/atoms/ModalContentText';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import HookFormPassword from '~/components/hookFormControls/PasswordControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import useErrors from '~/hooks/useErrors';
import { useAppSelector } from '~/store/hooks/useAppSelector';

import { getFormSchema } from './formSchema';

type Props = {
  onCancel: () => void;
  onSuccessDeletion: () => void;
};

type FormDataType = {
  phrase: string;
  password: string;
};
const DeleteAllDataConfirmationModal: FC<Props> = ({
  onCancel,
  onSuccessDeletion,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const profile = useAppSelector((state) => state.profile);
  const { t } = useTranslation('modal');
  const { reportUserErrors } = useErrors();
  const form = useForm<FormDataType>({
    resolver: yupResolver(getFormSchema(t)),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const { handleSubmit, setError } = form;

  const handleSuccessSubmit = async ({
    password,
  }: FormDataType): Promise<void> => {
    setIsLoading(true);
    try {
      await Api.checkUserPassword(password);
      await Api.deleteAccount(profile.id);
      setIsLoading(false);
      onSuccessDeletion();
    } catch (e: any) {
      if (e.message === 'Your password is incorrect') {
        setError('password', { message: e.message });
      } else {
        reportUserErrors({
          error: e,
          method: 'deleteAccount',
          userMessage: t('delete_account', {
            ns: 'error',
            defaultValue: 'Error deleting account. Try again later or',
          }),
        });
      }

      setIsLoading(false);
    }
  };

  return (
    <div>
      <ModalContentTitle
        text={t('delete_all_data_confirmation.title', 'Confirmation required')}
      />
      <ModalContentText
        sx={{
          marginBottom: '24px',
        }}
      >
        <Trans i18nKey="delete_all_data_confirmation.text" t={t}>
          For final verification enter this confirmation phrase below:{' '}
          <b>Delete My Account</b>
        </Trans>
      </ModalContentText>

      <FormProvider {...form}>
        <HookFormTextField
          InputLabelProps={{ shrink: true }}
          name="phrase"
          label={t(
            'delete_all_data_confirmation.form_phrase_label',
            'Confirmation phrase',
          )}
          placeholder={t(
            'delete_all_data_confirmation.form_phrase_placeholder',
            'Type “Delete My Account”',
          )}
          disabled={isLoading}
          fullWidth
          sx={{
            marginBottom: '40px',
          }}
        />
        <HookFormPassword
          InputLabelProps={{ shrink: true }}
          name="password"
          label={t(
            'delete_all_data_confirmation.form_password_label',
            'Your current password',
          )}
          placeholder={t(
            'delete_all_data_confirmation.form_password_placeholder',
            'Enter your password',
          )}
          disabled={isLoading}
          fullWidth
          sx={{
            marginBottom: '40px',
          }}
        />
      </FormProvider>

      <div>
        <Button disabled={isLoading} onClick={onCancel} variant="contained">
          {t('cancel', {
            ns: 'general',
            defaultValue: 'Cancel',
          })}
        </Button>

        <Button
          disabled={isLoading}
          onClick={handleSubmit(handleSuccessSubmit)}
          variant="text"
        >
          {t(
            'delete_all_data_confirmation.submit_button',
            'Delete account forever',
          )}
        </Button>
      </div>
    </div>
  );
};

export default DeleteAllDataConfirmationModal;
