import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import IconButton from '~/components/atoms/buttons/IconButton';
import InnerNavigation from '~/components/molecules/InnerNavigation';
import { settingsSidebar } from '~/core/sidebar';
import { ChatByIdPath } from '~/modules/chat/constants';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  children?: React.ReactNode;
};
const NavigationLayout: FC<Props> = ({ children }) => {
  const { t } = useTranslation('settings');
  const navigate = useNavigate();
  const sidebarGroups = Object.values(settingsSidebar.getItems());

  return (
    <div className={styles.container}>
      <div className={styles.container__nav_bar}>
        <IconButton
          onClick={(): void => {
            navigate(ChatByIdPath);
          }}
          variant="outlined"
          size="small"
          className={styles.container__back_arrow}
        >
          <Icon icon="material-symbols:arrow-back" />
        </IconButton>

        <Typography
          variant={Typographies.TITLE_LARGE}
          component="p"
          className={styles.container__title}
        >
          {t('general:settings', 'Settings')}
        </Typography>

        <InnerNavigation groups={sidebarGroups} />
      </div>
      <div className={styles.container__main}>{children}</div>
    </div>
  );
};

export default NavigationLayout;
