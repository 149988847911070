import React, { ReactElement } from 'react';

import { Icon as SidebarIcon } from '@iconify/react';
import { flowRight } from 'lodash';
import { generatePath } from 'react-router-dom';

import router, { RouteItemType } from '~/core/router';
import { sidebar } from '~/core/sidebar';
import {
  withHomeScreenRetentionLayout,
  withMainLayout,
  withOnboardingLayout,
  withPWAModalLayout,
  withSubModalsLayout,
} from '~/decorators/withLayout';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';
import {
  ChatByIdPath,
  getChatTitle,
  SidebarKey,
} from '~/modules/chat/constants';

const Chat = lazyComponentLoader(() => import('./views'));

const ChatByIdRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(Chat),
  path: ChatByIdPath,
  getTitle: getChatTitle,
};

router.addPrivateRoutes([ChatByIdRoute]);

sidebar.addItems({
  a: [
    {
      order: 3,
      key: SidebarKey,
      path: ChatByIdPath,
      redirectPath: generatePath(ChatByIdPath, { chatId: 'index' }),
      getTitle: ChatByIdRoute.getTitle,
      Icon: ({ isActive }): ReactElement => {
        return (
          <SidebarIcon icon={isActive ? `ph:chat-fill` : `ph:chat-bold`} />
        );
      },
    },
  ],
});
