import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

const Agreement: FC = () => {
  const { t } = useTranslation('subscription');

  return (
    <div className={styles.agreement}>
      <Typography
        className={styles.text}
        variant={Typographies.BODY_SMALL}
        component="p"
      >
        {t(
          'payment_form.agreement',
          'The payment processed by GM Appdev Limited, with registered address at Nafpliou, 15, Limassol, Cyprus, 3025',
        )}
      </Typography>
    </div>
  );
};

export default Agreement;
