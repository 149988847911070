import React, { FC, Fragment } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ONBOARDING_CARDS } from '~/modules/onboarding-new/constants';
import { TemplateVariantProps } from '~/modules/onboarding-new/types';

import styles from './styles.module.scss';

const TemplateVariant1: FC<
  TemplateVariantProps & {
    onSubmit: (value: string, nextKey?: ONBOARDING_CARDS) => void;
    onClick: () => void;
    value?: string;
  }
> = ({ value, onSubmit, onClick, variants, nextCard, Text, isWithDot }) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(styles.text, {
        [styles['text--active']]: Boolean(!value),
        [styles['text--pointer']]: Boolean(value),
      })}
      onClick={value ? onClick : undefined}
    >
      <Text>
        {value ? (
          <span className={styles.text__variable}>
            {variants.find((item) => item.value === value)?.getText(t) || value}
          </span>
        ) : (
          <span className={styles.text__variants}>
            {variants.map(({ getText, value, ...rest }, index) => (
              <Fragment key={index}>
                <span
                  className={clsx(
                    styles['text--pointer'],
                    styles.text__variants__item,
                  )}
                  onClick={(): void =>
                    onSubmit(value, rest.nextCard || nextCard)
                  }
                >
                  {getText(t)}
                </span>
                {index < variants.length - 1 && (
                  <span className={styles.text__delimiter}> / </span>
                )}
              </Fragment>
            ))}
          </span>
        )}
      </Text>
      {isWithDot && '.'}
    </div>
  );
};

export default TemplateVariant1;
