import React, { FC, useState } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DropdownCustom from '~/components/molecules/DropdownCustom';
import RadioGroup from '~/components/molecules/RadioGroup';
import ButtonsBlock from '~/modules/cancellation/components/ButtonsBlock';
import { Typographies } from '~/theme/typography';

import { CancellationFormDataType, FormFields } from '../../../types';
import styles from '../styles.module.scss';

type FormStepData = Pick<
  CancellationFormDataType,
  FormFields.REASON | FormFields.SUB_REASON | FormFields.RELATED_TO
>;

type Props = {
  title: string;
  options: Record<
    string,
    {
      text: string;
      value: string;
      subReasons: {
        text: string;
        value: string;
      }[];
    }
  >;
  relations: { text: string; value: string }[];
  onSubmit: (data: FormStepData) => void;
  onRefuse: () => void;
};

const ReasonStep: FC<Props> = ({
  title,
  options,
  relations,
  onSubmit,
  onRefuse,
}) => {
  const [form, setForm] = useState<FormStepData>({
    [FormFields.REASON]: '',
    [FormFields.SUB_REASON]: '',
    [FormFields.RELATED_TO]: '',
  });

  const { t } = useTranslation('cancellation');

  const handleChange = (key: keyof FormStepData, value: string): void => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
      ...(key === FormFields.REASON ? { [FormFields.SUB_REASON]: '' } : {}),
    }));
  };

  const handleSubmit = (): void => {
    onSubmit(form);
  };

  const isButtonDisabled =
    !form.reason ||
    (form.reason !== 'other' && !form.sub_reason) ||
    (relations.length > 0 && !form.related_to);

  const optionEntries = Object.entries(options);

  return (
    <div>
      <div className={styles.form__fieldset}>
        <Typography variant={Typographies.TITLE_LARGE} component="h4">
          {title}
        </Typography>
        <div className={styles.width_limit}>
          <DropdownCustom
            options={optionEntries.map(([, value]) => ({
              text: value.text,
              value: value.value,
            }))}
            selectedOption={
              form.reason
                ? {
                    text: options[form.reason].text,
                    value: options[form.reason].value,
                  }
                : {
                    text: t('v3.default_select_reason', 'Select a reason'),
                    value: 'Select a reason',
                  }
            }
            onOptionClick={(opt: { value: string; text: string }): void =>
              handleChange(FormFields.REASON, opt.value)
            }
          />
        </div>

        {options[form.reason] && (
          <RadioGroup
            name="sub_reason"
            value={form.sub_reason}
            onChange={(e): void =>
              handleChange(FormFields.SUB_REASON, e.target.value)
            }
            options={options[form.reason].subReasons}
          />
        )}
      </div>

      {(form.sub_reason || form.reason === 'other') && relations.length > 0 && (
        <div className={styles.form__fieldset}>
          <Typography variant={Typographies.TITLE_MEDIUM} component="p">
            This is related to ....
          </Typography>

          <RadioGroup
            name="related_to"
            value={form.related_to}
            onChange={(e): void =>
              handleChange(FormFields.RELATED_TO, e.target.value)
            }
            options={relations}
          />
        </div>
      )}

      <ButtonsBlock
        buttonSubmitText={t('continue', {
          ns: 'general',
          defaultValue: 'Continue',
        })}
        buttonRefuseText={t('cancel', {
          ns: 'general',
          defaultValue: 'Cancel',
        })}
        onButtonSubmit={handleSubmit}
        onButtonRefuse={onRefuse}
        isDisabledSubmitButton={isButtonDisabled}
      />
    </div>
  );
};

export default ReasonStep;
