import React, { ReactElement, useMemo } from 'react';

import { Icon as SidebarIcon } from '@iconify/react';
import { flowRight } from 'lodash';

import router, { RouteItemType } from '~/core/router';
import { sidebar } from '~/core/sidebar';
import {
  withHomeScreenRetentionLayout,
  withMainLayout,
  withMainSimpleLayout,
  withOnboardingLayout,
  withPWAModalLayout,
  withSubModalsLayout,
} from '~/decorators/withLayout';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';
import {
  AdvancedArticleGeneratorPath,
  AiDetectorPath,
  AiHumanizerPath,
  getAdvancedArticleGeneratorTitle,
  getAiDetectorTitle,
  getAiHumanizerTitle,
  getPaperWriterTitle,
  getParaphraseTextTitle,
  getPlagiarismCheckerTitle,
  getRewriteArticleTitle,
  getSummariseTextTitle,
  getToolsGalleryTitle,
  getToolTitle,
  PaperWriterPath,
  ParaphraseTextPath,
  PlagiarismCheckerPath,
  RewriteArticlePath,
  SummariseTextPath,
  ToolPath,
  ToolsGalleryPath,
  ToolsSidebarKey,
} from '~/modules/tools/constants';
import { useAppSelector } from '~/store/hooks/useAppSelector';

const ToolsGallery = lazyComponentLoader(() => import('./views/ToolsGallery'));
const Tool = lazyComponentLoader(() => import('./views/Tool'));

const AdvancedArticleGenerator = lazyComponentLoader(
  () => import('./views/AdvancedArticleGenerator'),
);

const PlagiarismChecker = lazyComponentLoader(
  () => import('./views/variant2/PlagiarismChecker'),
);

const PlagiarismCheckerV2 = lazyComponentLoader(
  () => import('./views/variant2/PlagiarismCheckerV2'),
);

const AiDetector = lazyComponentLoader(
  () => import('./views/variant2/AiDetector'),
);

const AiDetectorV2 = lazyComponentLoader(
  () => import('./views/variant2/AiDetectorV2'),
);

const AiHumanizer = lazyComponentLoader(
  () => import('./views/variant2/AiHumanizer'),
);

const AiHumanizerV2 = lazyComponentLoader(
  () => import('./views/variant2/AiHumanizerV2'),
);

const ParaphraseTextV2 = lazyComponentLoader(
  () => import('./views/variant2/ParaphraseTextV2'),
);

const ParaphraseTextV3 = lazyComponentLoader(
  () => import('./views/variant2/ParaphraseTextV3'),
);

const SummarizeText = lazyComponentLoader(
  () => import('./views/variant2/SummarizeText'),
);

const RewriteArticle = lazyComponentLoader(
  () => import('./views/variant2/RewriteArticle'),
);

const ToolsGalleryRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(ToolsGallery),
  path: ToolsGalleryPath,
  getTitle: getToolsGalleryTitle,
};

const AdvancedArticleGeneratorRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(AdvancedArticleGenerator),
  path: AdvancedArticleGeneratorPath,
  getTitle: getAdvancedArticleGeneratorTitle,
};

const PaperWriterRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(AdvancedArticleGenerator),
  path: PaperWriterPath,
  getTitle: getPaperWriterTitle,
};

const PlagiarismCheckerRoute: RouteItemType = {
  Component: () => {
    const { backend_ab_tests } = useAppSelector((state) => state.profile);
    const isPlagiarismV2 =
      backend_ab_tests?.['experiment-plagiarism-v2']?.includes('v2');

    const ComponentToRender = isPlagiarismV2
      ? PlagiarismCheckerV2
      : PlagiarismChecker;

    const WrappedComponent = useMemo(() => {
      const layouts = [
        withPWAModalLayout,
        withSubModalsLayout,
        withOnboardingLayout,
        withHomeScreenRetentionLayout,
        ...(isPlagiarismV2 ? [withMainSimpleLayout] : [withMainLayout]),
      ];

      return flowRight(...layouts)(ComponentToRender);
    }, [isPlagiarismV2]);

    return <WrappedComponent />;
  },
  path: PlagiarismCheckerPath,
  getTitle: getPlagiarismCheckerTitle,
};

const AiDetectorRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(() => {
    const { backend_ab_tests } = useAppSelector((state) => state.profile);
    const isDetectorV2 = backend_ab_tests?.experiment_ai_detector === 'v2';
    return isDetectorV2 ? <AiDetectorV2 /> : <AiDetector />;
  }),
  path: AiDetectorPath,
  getTitle: getAiDetectorTitle,
};

const AiHumanizerRoute: RouteItemType = {
  Component: () => {
    const { backend_ab_tests } = useAppSelector((state) => state.profile);
    const isHumanizerV2 =
      backend_ab_tests?.experiment_humanizer_v2?.includes('v2');

    const ComponentToRender = isHumanizerV2 ? AiHumanizerV2 : AiHumanizer;

    const WrappedComponent = useMemo(() => {
      const layouts = [
        withPWAModalLayout,
        withSubModalsLayout,
        withOnboardingLayout,
        withHomeScreenRetentionLayout,
        ...(isHumanizerV2 ? [withMainSimpleLayout] : [withMainLayout]),
      ];

      return flowRight(...layouts)(ComponentToRender);
    }, [isHumanizerV2]);

    return <WrappedComponent />;
  },
  path: AiHumanizerPath,
  getTitle: getAiHumanizerTitle,
};

const ParaphraseTextRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(() => {
    const { backend_ab_tests } = useAppSelector((state) => state.profile);

    const isParaphraserV3 =
      backend_ab_tests?.['experiment-paraphraser-v3'] === 'v3';

    return isParaphraserV3 ? <ParaphraseTextV3 /> : <ParaphraseTextV2 />;
  }),
  path: ParaphraseTextPath,
  getTitle: getParaphraseTextTitle,
};

const SummarizeTextRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(SummarizeText),
  path: SummariseTextPath,
  getTitle: getSummariseTextTitle,
};

const RewriteArticleRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(RewriteArticle),
  path: RewriteArticlePath,
  getTitle: getRewriteArticleTitle,
};

export const ToolRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(Tool),
  path: ToolPath,
  getTitle: getToolTitle,
};

router.addPrivateRoutes([
  ToolsGalleryRoute,
  AdvancedArticleGeneratorRoute,
  PaperWriterRoute,
  PlagiarismCheckerRoute,
  AiDetectorRoute,
  AiHumanizerRoute,
  ParaphraseTextRoute,
  SummarizeTextRoute,
  RewriteArticleRoute,
  ToolRoute,
]);

sidebar.addItems({
  a: [
    {
      order: 2,
      key: ToolsSidebarKey,
      path: ToolsGalleryRoute.path,
      getTitle: ToolsGalleryRoute.getTitle,
      Icon: ({ isActive }): ReactElement => {
        return (
          <SidebarIcon
            icon={
              isActive
                ? `material-symbols:library-books`
                : `material-symbols:library-books-outline`
            }
          />
        );
      },
    },
  ],
});
