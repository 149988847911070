import router, { RouteItemType } from '~/core/router';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';
import {
  ForgotPasswordPath,
  getForgotPasswordTitle,
  getResetPasswordTitle,
  getSignInTitle,
  getSignUpTitle,
  ResetPasswordPath,
  SignInPath,
  SignUpPath,
} from '~/modules/auth/constants';

const SignIn = lazyComponentLoader(() => import('./components/SignIn'));
const SignUp = lazyComponentLoader(() => import('./components/SignUp'));
const ForgotPassword = lazyComponentLoader(
  () => import('./components/ForgotPassword'),
);
const ResetPassword = lazyComponentLoader(
  () => import('./components/ResetPassword'),
);

const SignInRoute: RouteItemType = {
  Component: SignIn,
  path: SignInPath,
  getTitle: getSignInTitle,
};

const SignUpRoute: RouteItemType = {
  Component: SignUp,
  path: SignUpPath,
  getTitle: getSignUpTitle,
};
const ForgotPasswordRoute: RouteItemType = {
  Component: ForgotPassword,
  path: ForgotPasswordPath,
  getTitle: getForgotPasswordTitle,
};

const ResetPasswordRoute: RouteItemType = {
  Component: ResetPassword,
  path: ResetPasswordPath,
  getTitle: getResetPasswordTitle,
};

router.addAuthRoutes([
  SignInRoute,
  SignUpRoute,
  ForgotPasswordRoute,
  ResetPasswordRoute,
]);
